* {
  margin: 0;
  padding: 0;
  font-family: "Poppins","Lato", sans-serif;
}

/* Home.jsx */

.body {
  display: flex;
  flex-direction: column;
  background-image: url(./utilities/Point-One-Home.png);
  background-size: cover;
  height: 100vh;
}
.homeContent{
  width: 400px;
  height: 300px;
  /* margin-top: 0px; */
  margin-left: 150px;
}
.redLine{
  background-color: red;
  width: 50px;
  height: 2px;
}
.slogan1{
  font-family: 'Raleway', Courier, monospace;
  font-size: 20pt;
  font-weight: 600;
  color: #3b3d49;
  line-height: 35px;
}
.ahead{
  color: red;
  font-family: 'Raleway', Courier, monospace;
  font-size: 22pt;
}
.homeBtns button{
  margin-top: 30px;
  margin-right: 10px;
  width: 150px;
  height: 50px;
  font-size: 20px;
  font-family: 'Raleway', Courier, monospace;
  font-weight: 600;
  border: none;
  border-radius: 10px;
}

#homeBtns1{
  border: 2px #1abaaa solid;
  color: #1abaaa;
  background-color: rgba(0, 0, 0, 0.0);
}
#homeBtns2{
  background-color: #1abaaa;
  color: white;
}
/* Navbar1 */

.navContent{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
  margin-top: 30px;
  margin-left: 50px;
  margin-right: 50px;
}
.navContent img{
  width: 100px;
}
.navMenu{
  margin-left: 500px;
  font-size: 18px;
  font-family: 'Raleway', Courier, monospace;
  font-weight: 600;
  color: #3b3d49;
  display: flex;
  gap: 30px;
  list-style: none;
}

.navMenu li{
  position: relative;
  overflow: hidden;
  transition: .3s;
}
.navMenu li:hover {
  color: #1abaaa;
  cursor: pointer;
}

.navMenu li:before {
  content: "";
  position: absolute;
  inset: calc(100% - 3px) 0 0 0; /* 3px = the thickness */
  background: #1abaaa; /* the color */
  scale: 0 1;
  transition: .3s, translate 0s .3s;
}
.navMenu:hover li:before {
  scale: 1;
}
.navMenu li:hover:before {
  translate: 0;
  transition: .3s;
}
.navMenu:hover li:has(~ li:hover):before {
  translate: 100% 0;
  transition: .2s .2s,scale 0s .4s;
}
.navMenu:hover li:hover ~ li:before {
  translate: -100% 0;
  transition: .2s .2s,scale 0s .4s;
}

.navButton button{
  width: 150px;
  height: 50px;
  font-size: 18px;
  font-family: 'Raleway', Courier, monospace;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  background-color: #1abaaa;
  color: white;
}

.navButton button:hover{
  color: #1abaaa;
  border: 2px solid #1abaaa;
  background-color: rgba(0, 0, 0, 0.0);
  transition: .3s;
  cursor: pointer;
}


/* navbar */


nav {
  margin-top: 25px;
  width: 1240px;
  height: 150px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

}



#menu ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  width: 550px;
  cursor: pointer;
}

.close,
.open {
  display: none;
}




.nav__buttons {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  display: flex;
  gap: 30px;
}





/* Body */



/* Stats.jsx */

.stat {
  display: flex;
  justify-content: center;
}



.stats {
  background-color: white;
  display: flex;
  align-content: center;
  align-self: center;
  justify-content: center;
  align-items: center;
  gap: 120px;
  width: 1200px;
  height: 150px;
  border-radius: 10px;
  box-shadow: 4px 4px 15px black;
  margin-top: 0px;
}

.icon {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.icon span {
  margin-left: 12px;
}

#icon1 {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: rgba(19, 185, 171, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}



#icon2 {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: rgba(209, 22, 31, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}


#icon3 {
  width: 70px;
  height: 70px;
  border-radius: 10px;
  background-color: rgba(251, 194, 17, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Category.jsx */


.main {
  width: 100%;
  margin-top: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.title {
  font-size: 50px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.paragraph {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 400;
}

.card {
  background-color: aliceblue;
  width: 610px;
  height: 305px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.card #C {
  color: white;
  padding: 30px;
  width: 290px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  align-items: flex-start;
}

.card #c1 {
  font-size: 30px;
  font-weight: 600;
}

.card #c2 {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
}

.card #c3 {
  margin-top: 32px;
  width: 200px;
  height: 50px;
  border-radius: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: 300;
  background-color: white;
}



.Card {
  width: 1240px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

#A {
  background-color: #5DBE87;
}

#B {
  background-color: #FFC8A5;
}

.Smcard {
  width: 232px;
  height: 280px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 18px;
}

#A5 {
  background-color: #FFE9D9;
}

#A1 {
  background-color: #CEE0D2;
}

#A2 {
  background-color: #F4EEFA;
}

#A3 {
  background-color: #F5F5F5;
}

#A4 {
  background-color: #F7ECD8;
}

#D {
  width: 200px;
  height: 50px;
  border-style: none;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 300;
  background-color: white;
}

#a {
  margin-top: 3px;
}

#b {
  margin-bottom: 16px;
}

#e {
  margin-bottom: 44px;
}


/* Services */

.services {
  margin-top: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.CARD {
  width: 1240px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;

}

.cardd {
  margin-top: 40px;
  border-radius: 16px;
  width: 400px;
  height: 450px;
  box-shadow: 4px 4px 15px black;
  display: flex;
  flex-direction: column;
  gap: 13px;
  align-items: center;
}

.cardd #cardd {
  width: 330px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.cardd #t1 {
  font-size: 20px;
  font-weight: 600;
  color: #008981;
}

.cardd #t2 {
  font-size: 14px;
  font-weight: 400;
  color: #757575;
}

.cardd #t3 {
  font-size: 16px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  display: flex;
  gap: 10px;
}

/*Banner*/

.banner {
  margin-top: 150px;
  width: 100%;
  height: 178px;
  background-color: #005049;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner span {
  color: white;
  font-size: 30px;
  font-weight: 600;
}

.banner button {
  width: 220px;
  height: 40px;
  border-style: none;
  font-weight: 300;
  font-size: 20px;
  border-radius: 10px;
  background-color: white;
  margin-top: 10px;
}




/*Our Work*/

.ourwork {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ourwork h1 {
  font-size: 50px;
  font-weight: 800;
}

.ourwork p {
  font-size: 25px;
  font-weight: 400;
}

.m-ourwork{
  display: none;
}




.grid-container {
  display: grid;
  grid-template-columns: 250px 200px 200px 270px 240px;
  grid-template-rows: 400px 200px;
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.grid-item1 {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}



.grid-item2 {
  grid-row: 1 / 1;
  grid-column: 2 / 3;
  width: 420px;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}
.grid-item2 img{
  width: 100%;
}


.grid-item3 {
  grid-row: 1 / 1;
  grid-column: 4 / 4;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}


.grid-item4 {
  grid-row: 1 / 1;
  grid-column: 5 / 5;
  background-color: rgb(0, 255, 221);
  border-radius: 10px;
  overflow: hidden;
}

.grid-item4 img{
  width: 100%;
}

.grid-item5 {
  grid-row: 2 / 2;
  grid-column: 1 / 1;
  background-color: rgb(217, 0, 255);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  scale: 1;
}



.grid-item6 {
  grid-row: 2 / 2;
  grid-column: 2 / 2;
  height: 350px;
  border-radius: 10px;
  align-self: flex-end;
  overflow: hidden;
}
.grid-item6 img{
  width: 100%;
}


.grid-item7 {
  grid-row: 2 / 2;
  grid-column: 3 / 3;
  height: 350px;
  align-self: flex-end;
  background-color: rgb(255, 0, 115);
  border-radius: 10px;
  overflow: hidden;
}

.grid-item7 img{
  width: 100%;
}

.grid-item8 {
  grid-row: 2 / 2;
  grid-column: 4 / 4;
  border-radius: 10px;
  overflow: hidden;
}
.grid-item8 img{
  width: 100%;
}

.grid-item9 {
  grid-row: 2 / 2;
  grid-column: 5 / 5;
  background-color: rgb(209, 72, 72);
  border-radius: 10px;
  overflow: hidden;
}

.grid-item9 img{
  width: 100%;
  margin-top: -120px;
}

.ourwork button {
  width: 220px;
  height: 40px;
  border-radius: 10px;
  border-style: none;
  font-weight: 300;
  font-size: 20px;
}


/*Clients*/

.clients {
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.ZZ {
  display: flex;
  gap: 17px;
}


.Z {
  background-color: white;
  margin-top: 40px;
  width: 370px;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 15px black;
  padding: 23px;
  gap: 23px;
}


#z1 {
  font-weight: 700;
  width: 353px;
  font-size: 21px;
  color: #005049;
}

.z2{
  margin-left: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

#z2a{
  width: 45px;
  height: 45px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  background-color: #005049;
}
#z2a img{
  position: relative;
  width: 28px;
  bottom: 10px;
  object-fit: cover;
}

#z2b {
  display: flex;
  flex-direction: column;
}

#z2b1{
  font-size: 30px;
  font-family: "Pompiere", sans-serif;
  color: #005049;
}

#z2b2{
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

/*Quote*/

/* .Q{
  margin-top: 150px;
  width: 100%;
  height: 780px;
  background-color: #FFFFF3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Q2{
  margin-top: 40px;
  border: 2px solid black;
  width: 1240px;
  height: 600px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 74px;
}

.Q2b{
  width: 506px;
  height: 496px;
  background-color: #005049;
  border-radius: 5px;
} */

.Q{
  display: none;
}

.YY{
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.YY p{
  margin-bottom: 40px;
}

.Y{
  width: 1240px;
  display: grid;
  grid-template-columns: 610px 610px;
  grid-template-rows: 80px 80px 180px 180px 80px;
  gap: 20px;
}


.y1{
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.y1 input{
  width: 610px;
  height: 50px;
  border-radius: 10px;
  border-color: #13B9AB;
  border-top-style: none;
  border-left-style: none;
  border-width: 1px;
  box-shadow: 4px 4px 15px gray ;
}

.y2{
  grid-row: 1 / 1;
  grid-column: 2 / 2;
}

.y2 input{
  width: 610px;
  height: 50px;
  border-radius: 10px;
  border-color: #13B9AB;
  border-top-style: none;
  border-left-style: none;
  border-width: 1px;
  box-shadow: 4px 4px 15px gray ;
}

.y3{
  grid-row: 2 / 2;
  grid-column: 1 / 1;
}

.y3 input{
  width: 610px;
  height: 50px;
  border-radius: 10px;
  border-color: #13B9AB;
  border-top-style: none;
  border-left-style: none;
  border-width: 1px;
  box-shadow: 4px 4px 15px gray ;
}

.y4{
  grid-row: 2 / 2;
  grid-column: 2 / 2;
}

.y4 input{
  width: 610px;
  height: 50px;
  border-radius: 10px;
  border-color: #13B9AB;
  border-top-style: none;
  border-left-style: none;
  border-width: 1px;
  box-shadow: 4px 4px 15px gray ;
}

.y5{
  grid-row: 3 / 3;
  grid-column: 1 / 2;
}

.y5 textarea{
  width: 1240px;
  height: 150px;
  border-radius: 10px;
  border-color: #13B9AB;
  border-top-style: none;
  border-left-style: none;
  border-width: 1px;
  box-shadow: 4px 4px 15px gray ;
  }

.y5 ::placeholder{
  color: grey;
}

.y6{
  grid-row: 4 / 4;
  grid-column: 1 / 2;
}


.y6a{
  width: 1240px;
  height: 150px;
  border-style: dashed;
  border-radius: 10px;
  border-color: #13B9AB;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 2px;
}

.y6a{
  display: flex;
  flex-direction: column;
  gap: 5px;
}




.y6a-img{
  width: 24px;
}

.y6a span{
  font-weight: 300;
  font-size: 15px;
}

#dropzone-file{
  visibility: hidden;
}

.y6b{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.y6b-upload{
  width: 500px;
  height: 50px;
  background-color: #D9D9D9;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 8px;
}

.y6b-upload span{
  display: inline;
}



.fileList{
  display: flex;
  flex-direction: column-reverse;
  gap: 3px;
}


#uploading2{
  color: red;
}

#uploading3{
  color: green;
}


#y6b-filesize{
  font-weight: 400;
  font-size: 10px;
}


#y6b-filename{
  font-weight: 600;
  font-size: 12px;
  width: 100%;
}

.tick-icon{
  justify-self: flex-end;
}




.y7{
  grid-row: 5 / 5;
  grid-column: 2 / 2;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.y7a{
  width: 119px;
  height: 40px;
  background-color: white;
  color: #13B9AB;
  border-radius: 10px;
  border: none;
  box-shadow: 4px 4px 15px gray ;
}

.y7b{
  width: 120px;
  height: 40px;
  background-color: #13B9AB;
  color: white;
  border-radius: 10px;
  border: none;
  box-shadow: 4px 4px 15px gray ;
}


/*Contact Us*/

.X {
  display: block;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.X2 {
  margin-top: 40px;
  display: flex;
  gap: 20px;
}


.x2 {
  border-right-style: inset;
  border-right-width: 1px;
  width: 295px;
  height: 46px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.x3 {
  margin-left: 22px;
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background-color: #3578EA;
  display: flex;
  align-items: center;
  justify-content: center;
}

.x4 {
  display: flex;
  flex-direction: column;
}

#x4a {
  font-size: 12px;
  font-weight: 400;
}

#x4b {
  font-size: 16px;
  font-weight: 400;
}

#x3a {
  background-color: #17D24B;
}

#x3b {
  background-color: #EF4444;
}

#x3c {
  background-color: #22C7DE;
}

#x2a {
  border: none;
}

.X3 {
  margin-top: 40px;
  width: 1240px;
  height: 500px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 56px;
  box-shadow: 4px 4px 15px rgb(0, 0, 0) ;

}

.x5 {
  width: 850px;
  height: 500px;
  border-radius: 10px;
  overflow: hidden;
  background-color: #17D24B;
}

.x6{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.x6a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.x6a1 {
  font-size: 29px;
  font-weight: 600;
}

.x6a2 {
  font-size: 20px;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 11px;
}


.x6b{
  width: 205px;
  height: 1px;
  background-color: black;
  margin-bottom: 23px;
}

.x6c{
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-top: 7px;
}

.x6c1 {
  width: 7px;
  height: 7px;
  border-radius: 100%;
}

#green {
  background-color: #00C868;
}

#red {
  background-color: #F50000;
}

.day{
  width: 90px;
  margin-left: 10px;
}

.time{
  width: 90px;
  display: flex;
  justify-content: flex-end;
  margin-left: 88px;
}

#closed{
  color: #F50000;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;

}

/*Footer*/

.W {
  display: block;
  width: 100%;
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #EEEEEE;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.w1 {
  margin-top: 40px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.w1a {
  background-color: black;
  width: 545px;
  height: 1px;
}

.w2 {
  display: flex;
  gap: 102px;
}

.w2a span {
  font-size: 16px;
}

.w2a ul {
  list-style: none;
  margin-top: 20px;
}

.w2a li {
  font-size: 12px;
}

.w3 {
  margin-top: 60px;
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.m-footer{
  display: none;
}

/* Digital Printing */

.D {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.D2 {
  display: flex;
  align-items: center;
  width: 1184px;
  gap: 68px;
}

.d2a h1 {
  font-size: 60px;
  font-weight: 200;
  line-height: 60px;
  margin-bottom: 20px;
}

.d2a span {
  font-size: 70px;
  font-weight: 400;
  line-height: 80px;
}

.d2a p {
  font-size: 20px;
  font-weight: 200;
}

.d2a ul {
  font-size: 20px;
  font-weight: 200;
}

/* footer */
.f-bg{
  background-image: url(../src/utilities/footerBg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  margin-top: 150px;
  color: white;
  display: flex;
  align-items: end;
  justify-content: center;
}
.f-main{
  width: 1240px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 45px;
}
.f-section1{
  display: flex;
  align-items: end;
}
.f-logo img{
  width: 250px;
}
.f-quote{
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 40px;
  margin-left: 70px;
}
.f-quotes img{
  width: 37.75px;
  margin-bottom: 90px;
}
.f-section2{
  display: flex;
  justify-content: center;
  gap: 66px;
}
.f-section2 span{
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}
.f-section2 li{
  font-size: 12px;
  font-weight: 300;
  list-style: none;
}

.f-section3{
  display: flex;
  margin-bottom: 45px;
  justify-content: space-between;
}
.f-section3a{
  width: 193px;
}
.f-section3a span{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.f-section3b{
  display: flex;
  flex-direction: column;
  align-items: end;
}
.f-section3b span{
  margin-top: 17px;
}
.f-section3b input{
  width: 345px;
  height: 30px;
  border-radius: 30px;
  border-style: none;
}
.f-section3b input::placeholder{
  font-size: 12px;
  text-align: left;
}
.f-section3b button{
  width: 92px;
  height: 30px;
  border-radius: 30px;
  border-style: none;
  background-color: #005049;
  color: white;
  font-weight: 700;
  font-size: 12px;
}
.f-section3b span{
  font-size: 12px;
  font-weight: 700;
}

/* LargeFormat */
.largeFormatMain{
  color: white;
  background-color: black;
}

.lf-section1{
  /* background-image: url(./utilities/billboard.png); */
  width: 100%;
  height: 100vh;
  /* background-repeat: no-repeat; */
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1)), url(./utilities/billboard.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.LF-nav{
  width: 800px;
  height: 70px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #D9D9D9;
  font-family: "Lato", sans-serif;
  margin-top: 50px;
}
.LF-nav ul{
  list-style: none;
  display: flex;
  gap: 50px;
  color: #005049;
  font-weight: 900;
  font-size: 20px;
}
.LF-nav p{
  font-weight: 600;
  color: black;
  font-size: 22px;
}

.LF-nav img{
  width: 90px;
}
.lf-about{
  margin-right: 221px;
  margin-top: 270px;
}
.lf-about h1{
  font-size: 90px;
  width: 602px;
  font-weight: 500;
}
.lf-about span{
  font-size: 60px;
  font-weight: 300;
}
.lf-about p{
  width: 640px;
  margin-top: 56px;
  font-size: 14px;
  font-weight: 300;
}

.lf-section2{
  background-color: black;
  margin-top: 250px;
  display: flex;
  justify-content: center;
  gap: 50px;
}
.lf-section2 ul{
  list-style: none;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  color: #B5B5B5;
}
.lf-section3{
  margin-top: 187px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
.lf-section3a{
  width: 650px;
}
.lf-section3a h1{
  font-size: 50px;
  margin-bottom: 50px;
  font-weight: 600;
}
.lf-info{
  font-size: 20px;
  margin-top: 20px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}
.lf-section3 img{
  width: 540px;
}

.lf-section4{
  background-color: black;
}







@media screen and (max-width: 576px) {

  /* navbar */
  nav {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-around;
    margin: 0;
  }

  .nav__logo img {
    width: 150px;
  }

  .menu__open {
    display: flex;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #02756e;
    color: white;
    top: 0%;
  }

  #menu ul {
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 60px;
    margin: 25%;
    list-style: none;
  }

  .menu__close {
    display: none;
  }

  .close {
    display: block;
    position: absolute;
    width: 28px;
    margin-top: 10%;
    display: flex;
    justify-content: flex-end;
    right: 9%;
  }

  .open {
    display: block;
  }



  .nav__buttons {
    display: none;
  }

  .open {
    width: 30px;
    margin-left: 150px;
  }

  /* Body */
  .body {
    background-image: url(./utilities/background.png);
    background-size: cover;
  }

  .slogan {
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: "Poppins", sans-serif;
    text-align: center;
  }

  .slogan1 {
    width: 804px;
    font-size: 30px;
    font-weight: 300;
    width: 400px;
    line-height: 50px;
  }

  .slogan2 {
    margin-top: 10px;
    width: 313px;
    font-size: 10px;
    font-weight: 200;
  }

  .slogan button {
    margin-top: 10px;
    width: 88px;
    height: 22px;
    border: none;
    border-radius: 50px;
    background-color: #FBC210;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 50px;
  }

  /* stats */

  .stat {
    display: flex;
    justify-content: center;
  }



  .stats {
    background-color: white;
    display: flex;
    align-content: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 350px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 4px 4px 15px black;
    margin-top: -30px;
  }

  .icon {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
  }

  .icon span {
    margin-left: 5px;
  }

  #icon1 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: rgba(19, 185, 171, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #icon1 img,
  #icon2 img,
  #icon3 img {
    width: 25px;
  }



  #icon2 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: rgba(209, 22, 31, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  #icon3 {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: rgba(251, 194, 17, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
  }


  /* Category.jsx */


  .main {
    width: 100%;
    margin-top: 75px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
  }

  .paragraph {
    margin-top: 0px;
    font-size: 10px;
    font-weight: 400;
  }

  .card {
    background-color: aliceblue;
    width: 310px;
    height: 152px;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

  .card #C {
    color: white;
    padding-left: 12px;
    width: 140 px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    align-items: flex-start;
  }

  .card #c1 {
    font-size: 15px;
    font-weight: 600;
  }

  .card #c2 {
    font-size: 10px;
    font-weight: 300;
  }

  .card #c3 {
    margin-top: 23px;
    width: 90px;
    height: 22px;
    border-radius: 50px;
    border-style: none;
    font-size: 10px;
    font-weight: 300;
    background-color: white;
  }

  .card img {
    width: 140px;
  }


  .Card {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
  }

  #A {
    background-color: #5DBE87;
  }

  #B {
    display: none;
  }

  .Smcard {
    width: 149px;
    height: 180px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 18px;
  }

  #A5 img {
    width: 134px;
    margin-bottom: 10px;
  }

  #A5 {
    background-color: #FFE9D9;
  }

  #A1 {
    background-color: #CEE0D2;
  }

  #A1 img {
    width: 127px;
    margin-bottom: 20px;
  }

  #A2 {
    background-color: #F4EEFA;
    display: none;
  }

  #A3 {
    background-color: #F5F5F5;
    display: none;
  }

  #A4 {
    background-color: #F7ECD8;
    display: none;
  }

  #D {
    width: 100px;
    height: 25px;
    border-style: none;
    border-radius: 50px;
    font-size: 10px;
    font-weight: 300;
    background-color: white;
  }

  /* Services */

  .services {
    margin-top: 75px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .CARD {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .cardd {
    margin-top: 12px;
    border-radius: 8px;
    width: 310px;
    height: 340px;
    box-shadow: 4px 4px 15px black;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  .cardd__img {
    width: 310px;
  }

  .cardd #cardd {
    width: 260px;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .cardd #t1 {
    font-size: 15px;
    font-weight: 600;
    color: #008981;
  }

  .cardd #t2 {
    font-size: 11px;
    font-weight: 400;
    color: #757575;
    margin-top: 2px;
  }

  .cardd #t3 {
    font-size: 12px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    display: flex;
    gap: 7px;
    align-items: center;
    margin-top: 10px;
  }

  /*Banner*/

  .banner {
    margin-top: 75px;
    width: 100%;
    height: 62px;
    background-color: #005049;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .banner span {
    color: white;
    font-size: 15px;
    font-weight: 600;
  }

  .banner button {
    width: 110px;
    height: 20px;
    border-style: none;
    font-weight: 300;
    font-size: 12px;
    border-radius: 5px;
    background-color: white;
    margin-top: 0px;
  }

  /*Our Work*/
#cont{
  display: none;
}
.m-ourwork{
  display: block;
}
.ourwork {
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ourwork h1 {
  font-size: 20px;
  font-weight: 800;
}

.ourwork p {
  font-size: 10px;
  font-weight: 400;
}

.grid-container {
  display: grid;
  grid-template-columns: 270px;
  grid-template-rows:200px 200px 200px;
  background-color: white;
  margin-top: 20px;
  margin-bottom: 20px;
}

.grid-item1 {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.grid-item2 {
  display: none;
}

.grid-item3 {
  grid-row: 2 / 2;
  grid-column: 1 / 1;
  border-radius: 10px;
}

.grid-item4 {
  grid-row: 3 / 3;
  grid-column: 1 / 1;
}

.grid-item5 {
  display: none;
}

.grid-item6 {
  display: none;
}

.grid-item7 {
  display: none;
}

.grid-item8 {
  display: none;
}

.grid-item9 {
  display: none;
}

.ourwork button {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  border-style: none;
  font-weight: 400;
  font-size: 12px;
}

/*Clients*/

.clients {
  margin-top: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.clients h1{
  font-size: 20px;
}

.clients p{
  font-size: 10px;
}

.ZZ {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.Z {
  background-color: white;
  margin-top: 20px;
  width: 270px;
  height: 150px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 4px 4px 15px black;
  padding: 14px;
  gap: 10px;
}


#z1 {
  font-weight: 700;
  width: 251px;
  font-size: 15px;
  color: #005049;
}

.z2{
  margin-left: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

#Z{
  display: none;
}

#z2a{
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  background-color: #005049;
}
#z2a img{
  position: relative;
  width: 17px;
  bottom: 5px;
  object-fit: cover;
}

#z2b {
  display: flex;
  flex-direction: column;
}

#z2b1{
  font-size: 18px;
  font-family: "Pompiere", sans-serif;
  color: #005049;
}

#z2b2{
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

/* Quote */

.YY{
  display: none;
}

.Q{
  display: block;
  margin-top: 75px;
  background-color: #FFFFF3;
  width: 100%;
  height: 885px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Q1{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Q1 h1{
  font-size: 20px;
}

.Q1 p{
  font-size: 10px;
}

.Q2{
  margin-top: 10px;
  border: 2px solid black;
  width: 385px;
  height: 800px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 23px;
  gap: 48px;
}

.Q2a{
  font-size: 12px;
  display: flex;
  gap: 5px;
}

.Q2a input, textarea {
  display: flex;
  width: 340px;
 }

 br{
  width: 12px;
 }



.Q2b{
  width: 340px;
  height: 333px;
  background-color: #005049;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 10px;
} 

.Q2ba{
  font-size: 40px;
  font-weight: bold;
  color: white;
  margin-left: -5px;
}

.Q2bb{
  font-size: 12px;
  color: white;
  font-weight: 400;
  line-height: 15px;
  width: 295px;
  align-self: center;
  margin-top: 26px;
}

.Q2c{
  position: absolute;
  top: 246px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 22px;
}

.Q2c span{
  color: white;
  font-size: 12px;
}

.Q2c1{
  display: flex;
  align-items: center;
  gap: 7px;
}


.Q-img{
  width: 185.95px;
  margin-top: -3px;
  position: relative;
  margin-left: 160px;
}

/* Contact Us */

.X{
  display: none;
}

/* Footer */

.W{
  display: none;
}

.m-footer{
  display: block;
  margin-top: 75px;
  width: 100%;
  height: 500px;
  background-color: #1E1E1E;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.m-footer-1{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
}

.footer-line{
  width: 170px;
  height: 1px;
  background-color: white;
}

.footer-logo{
  width: 119px;
}

.m-footer-2{
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 142px;
  font-weight: 300;
  font-size: 12px;
}

.m-footer-2 ul{
  list-style: none;
}

.m-footer-3{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-map{
  margin-top: 34px;
  width: 200px;
  height: 200px;
}

.footer-3p{
  width: 154px;
  height: 36px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}

.m-footer-4{
  margin-top: 20px;
  font-weight: 300;
  font-size: 6px;
  display: flex;
}

}